<template>
    <div class="list-manage my-collection">
        <section class="list-manage-table">
            <div class="table-title">
                <span class="title">发票管理</span>
                <span>
                    <el-input placeholder="请输入订单号" prefix-icon="el-icon-search" v-model="search" oninput="value=value.replace(/[^0-9.]/g,'')" class="searchBar" clearable @keyup.enter.native="searchList">
                    </el-input>
                    <el-button class="searchBtn" @click="searchList">搜索</el-button>
                </span>
            </div>
            <div class="thead">
                <span class="th">结算编号</span>
                <span class="th">创建时间</span>
                <span class="th">订单号</span>
                <span class="th">类型</span>
                <span class="th">结算金额</span>
                <!-- <span class="th">名称</span>
                <span class="th">地点</span> -->
                <span class="th">状态</span>
            </div>
            <template v-if="list.length != 0">
                <div class="tbody" v-loading="loading">
                    <div class="list-item" v-for="(item, index) in list" :key="index">
                        <el-checkbox-group v-model="checkedCities" @change="handleCheckedChange(item.id, item.reality_rental_fee, index)">
                            <div class="list-item-header">
                                <span class="order-number">
                                    <el-checkbox :label="cities[index]" :index="cities[index]"> <br /> </el-checkbox>
                                    结算单编号：{{ item.id }}
                                </span>
                                <span class="order-number">
                                    项目名称：{{ item.name }}
                                </span>
                                <span class="order-number">
                                    地址：{{ item.address }}
                                </span>
                                <!-- <span class="status" style="width: 100px">{{ item.order_state_name }}</span> -->
                            </div>
                        </el-checkbox-group>
                        <div class="list-item-main">
                            <div class="td">{{ item.id }}</div>
                            <div class="td">{{ item.create_time }}</div>
                            <div class="td">{{ item.order_id }}</div>
                            <div class="td">{{ item.order_state_name }}</div>
                            <div class="td f-orange">¥{{ item.reality_rental_fee }}</div>
                            <!-- <div class="td" :title="item.name">{{ item.name }}</div>
                            <div class="td" :title="item.address">{{ item.address }}</div> -->
                            <div class="td">{{ item.receipt_img == null ? "未开票" : "已开票" }}</div>
                        </div>
                    </div>
                </div>
            </template>
            <div v-else-if="list.length === 0 && !loading" style="text-align: center;height: 40px;line-height: 40px;font-size: 18px;">暂无数据</div>
        </section>
        <div class="pagination">
            <span class="total">总金额：<span style="color:#FD5C00;font-weight:700;height:42px;line-height:42px">¥&nbsp;{{ totals }}</span> </span>
            <el-pagination background layout="total,prev, pager, next,jumper" :page-size="page.pageSize" :current-page="page.pageNumber" :total="total.totalRow" @current-change="handleCurrentChange">
            </el-pagination>
        </div>
        <div class="tac"><span class="base-btn" @click="toApply">申请开票</span></div>
        <!-- 申请发票s -->
        <el-dialog title="申请发票" :visible.sync="addBillDialogVisible" width="800px" class="add-bill-dialog" :before-close="handleClose">
            <span slot="title" class="dialog-footer">
                <span>申请发票</span>
                <span @click="selectbill" class="GoBill" style="color: red;">选择抬头</span>
            </span>
            <div class="add-bill-form">
                <div class="form-item">
                    <div class="form-label" style="width: 700px;font-size:16px">开票总金额：<span style="color:#ff992b;font-weight:700;font-size:18px">¥&nbsp;{{ totals }}</span></div>
                </div>
                <div class="form-item">
                    <div class="form-label">名称：</div>
                    <el-input v-model="input.head_name" placeholder="请输入名称…" style="width: 340px;" readonly></el-input>
                </div>
                <div class="form-item">
                    <div class="form-label">抬头类型：</div>
                    <div class="radio" red>
                        <el-radio v-model="input.type" :label="1" style="width: 120px;" disabled>个人/事业单位</el-radio>
                        <el-radio v-model="input.type" :label="2" style="width: 200px;" disabled>单位</el-radio>
                    </div>
                </div>
                <div class="form-item">
                    <div class="form-label">税号：</div>
                    <el-input v-model="input.taxpayer_no" placeholder="请输入税号…" style="width: 340px;" readonly></el-input>
                </div>
                <div class="form-item">
                    <div class="form-label">单位地址：</div>
                    <el-input v-model="input.company_address" placeholder="请输入单位地址…" style="width: 340px;" readonly></el-input>
                </div>
                <div class="form-item">
                    <div class="form-label">电话号码：</div>
                    <el-input v-model="input.phone" placeholder="请输入电话号码…" style="width: 340px;" readonly></el-input>
                </div>
                <div class="form-item form-input-pr60">
                    <div class="form-label">开户银行：</div>
                    <el-input v-model="input.bank" placeholder="请输入开户银行…" style="width: 340px;" readonly></el-input>
                    <i class="icon bill-icon-location"></i>
                </div>
                <div class="form-item form-input-pr60">
                    <div class="form-label">银行账户：</div>
                    <el-input v-model="input.bank_account" placeholder="请输入银行账户…" style="width: 340px;" readonly></el-input>
                    <i class="icon bill-icon-cal cap"></i>
                </div>
                <div class="form-item">
                    <div class="form-label">收件人姓名：</div>
                    <el-input v-model="input.receiv_name" placeholder="请输入收件人姓名…" style="width: 340px;" readonly></el-input>
                </div>
                <div class="form-item">
                    <div class="form-label">收件单位地址：</div>
                    <el-input v-model="input.receiv_address" placeholder="请输入收件单位地址…" style="width: 340px;" readonly></el-input>
                </div>
                <div class="form-item">
                    <div class="form-label">收件电话号码</div>
                    <el-input v-model="input.receiv_phone" placeholder="请输入收件电话号码…" style="width: 340px;" readonly></el-input>
                </div>
            </div>
            <div class="footer-btn"><span class="base-btn" @click="saveBill">申请发票</span></div>
        </el-dialog>
        <!-- 申请发票e -->
        <!-- 发票抬头列表 -->
        <el-dialog title="抬头选择" :visible.sync="addBillDialogVisible1" width="800px" class="add-bill-dialog" :before-close="handleClose">
            <span slot="title" class="dialog-footer">
                <span>抬头选择</span>
                <span @click="gotoBill" class="GoBill" style="color: red;">新增抬头</span>
            </span>
            <template v-if="blist.length != 0">
                <div class="bill-manage-item" v-for="(item, index) in blist" :key="index">
                    <div class="info">
                        <div class="info-item"><span class="label">发票抬头：</span>{{ item.head_name }}</div>
                        <div class="info-item"><span class="label">类型：</span> <span v-if="item.type === 1">个人/事业单位</span> <span v-else>单位</span> </div>
                    </div>
                    <div class="btn-group">
                        <span class="btn" @click="select(index)">选择</span>
                    </div>
                </div>
            </template>
            <div v-else-if="blist.length === 0" style="width: 100%;height: 80%;margin: 0 auto;display: flex;flex-direction: column;justify-content: center;font-size: 18px;">
                <div style="display: flex;justify-content: center;">您暂无抬头，请 <span style="color: red;cursor: pointer;" @click="$router.push({ path: '/personalCenter/billManage', query: { go: 1 } })">添加抬头</span> </div>
                <div style="display: flex;justify-content: center;background-color: #FF992B; height: 50px;
              padding: 0 12px;margin: 10px auto 0;
              display: inline-flex;border-radius: 50px;
              align-items: center;
              justify-content: center;
              background-color: #ff992b;
              transition: .3s;
              cursor: pointer;
              font-size: 20px;
              color: #fff;
              font-weight: 700;
              width: 150px;" @click="gotoBill">
                    添加抬头
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { order_info, rec_save, ord_info, rec_list } from '@/api/contract'
import { getuser } from "@/api/user"
import kits from "@/kits"
// import { mes_add } from '../../api/contract';
export default {
    data() {
        return {
            sendIds: [],
            key: 0,
            addBillDialogVisible1: false,
            user: {},
            list: [],
            listll: [],
            // 抬头列表
            blist: [],
            page: {
                pageNumber: 1,
                pageSize: 10,
            },
            total: {
                totalPage: null,
                totalRow: null
            },
            search: null,
            checkedCities: [],
            cityOptions: [],
            cities: [],
            totals: 0,
            // 添加发票头输入内容
            addBillDialogVisible: false,
            input: {
                consumer_id: '',
                head_name: "",
                taxpayer_no: "",
                company_address: "",
                bank: "",
                bank_account: "",
                phone: "",
                receiv_name: "",
                receiv_address: "",
                receiv_phone: "",
                type: '1',
                receipt_header: '',
            },
            rec_header: null,
            checkinputs: [false, false, false, false, false, false, false, false, false, false],
            loading: true,
            deppInput: null,
            listA: [],
            listB: [],
            searched: 1,
        };
    },
    created() {
        this.deppInput = JSON.parse(JSON.stringify(this.input))
        this.user = getuser()
        // this.getinfo()
        this.input.consumer_id = this.user.id

        //获取 order_id 
        if(this.$route.query.order_id){
            this.search = this.$route.query.order_id
        }
        this.getAllList()

        ord_info({ inputs: { flag: 1, order_state: [2, 6] } }).then(res => {
            this.listA = res.data.statement_order.list
            for (let index = 0; index < this.listA.length; index++) {
                if (this.listA[index].order_state) {
                    this.listA[index].order_state_name = this.listA[index].order_state == 1 ? '预约待审核' : this.listA[index].order_state == 2 ? '中期完结' : this.listA[index].order_state == 3 ? '预约失败' : this.listA[index].order_state == 4 ? '结算中' : this.listA[index].order_state == 5 ? '结算失败' : '最终完结'
                }
                if (this.listA[index].create_time) {
                    let time = this.listA[index].create_time.split(" ")
                    this.listA[index].create_time = time[0]
                }
            }
        })
    },
    methods: {
        // getinfo(){
        //   ord_info({inputs:{},pagination:this.page}).then(res => {
        //     this.list = res.data.list
        //     let listB =[]
        //     for(let i = 0 ; i < this.list.length;i++) {
        //       if(this.list[i].order_state) {
        //         this.list[i].order_state_name = this.list[i].order_state == 1 ? '预约待审核' :  this.list[i].order_state == 2 ? '预约成功' : this.list[i].order_state == 3 ? '预约失败' :  this.list[i].order_state == 4 ? '结算中' :  this.list[i].order_state == 5 ? '结算失败' : '已完结'
        //       }
        //       if(this.list[i].rental_time_start){
        //         let time = this.list[i].rental_time_start.split(" ")
        //         this.list[i].rental_time_start = time[0]
        //       }
        //       if(this.list[i].rental_time_end){
        //         let time = this.list[i].rental_time_end.split(" ")
        //         this.list[i].rental_time_end = time[0]
        //       }
        //       if(this.list[i].create_time) {
        //         let time = this.list[i].create_time.split(" ")
        //         this.list[i].create_time = time[0]
        //       }
        //       if(this.list[i].order_state == 6) {
        //         listB.push(this.list[i])
        //       }
        //       this.cityOptions.push(this.list[i].id)
        //     }
        //     this.list = listB
        //     this.total.totalRow = this.list.length
        //     this.cities = this.cityOptions
        //   })
        // },
        // 分页处理数据
        getlist() {
            let a
            order_info({ inputs: { order_state: [3] }, pagination: this.page }).then(res => {
                this.cityOptions = []
                this.list = res.data.order_list
                this.total.totalRow = res.data.total
                if (this.list) {
                    for (let i = 0; i < this.list.length; i++) {
                        this.list.show_img = kits.img.url(this.list.show_img)
                        a = this.list.rental_type == 0 ? '1' : this.list.rental_type == 1 ? '30' : this.list.rental_type == 2 ? '180' : '365'
                        this.list.total_money = (this.list.rental_time * a * this.list.rental_fee * this.list.num)
                        this.list.rental_type_name = this.list.rental_type == 0 ? '日' : this.list.rental_type == 1 ? '月' : this.list.rental_type == 2 ? '半年' : '年'
                        this.cityOptions.push(this.list.order_id)
                    }
                }
                this.cities = this.cityOptions
            })
        },
        // 获取全部数据？
        getAllList() {
            console.log("----------this.search",this.search)
            let inputs  = { flag: 1,  order_state: [2, 6] }
            if(this.search){
                inputs.order_id = this.search
            }
            ord_info({ inputs: inputs , pagination: this.page }).then(res => {
                this.cityOptions = []
                this.listll = res.data.statement_order.list
                for (let index = 0; index < this.listll.length; index++) {
                    if (this.listll[index].order_state) {
                        this.listll[index].order_state_name = this.listll[index].order_state == 1 ? '预约待审核' : this.listll[index].order_state == 2 ? '中期完结' : this.listll[index].order_state == 3 ? '预约失败' : this.listll[index].order_state == 4 ? '结算中' : this.listll[index].order_state == 5 ? '结算失败' : '最终完结'
                    }
                    if (this.listll[index].rental_time_start) {
                        let time = this.listll[index].rental_time_start.split(" ")
                        this.listll[index].rental_time_start = time[0]
                    }
                    if (this.listll[index].rental_time_end) {
                        let time = this.listll[index].rental_time_end.split(" ")
                        this.listll[index].rental_time_end = time[0]
                    }
                    if (this.listll[index].create_time) {
                        let time = this.listll[index].create_time.split(" ")
                        this.listll[index].create_time = time[0]
                    }
                }
                for (let index = 0; index < this.listll.length; index++) {
                    this.cityOptions.push(this.listll[index].id)
                }
                this.list = this.listll
                this.cities = this.cityOptions
                this.total.totalRow = res.data.statement_order.totalRow
                this.loading = false
            })
        },
        handleCurrentChange(val) {
            this.page.pageNumber = val
            if (this.listB.length > 0) {
                this.searchList()
            } else {
                this.getAllList()
            }
        },
        toApply() {
            if (!this.checkedCities.length > 0) {
                this.$message({
                    message: '请先选择结算单单再进行申请发票',
                    type: 'warning'
                });
            } else {
                this.addBillDialogVisible = true
            }
        },
        // 计算金额   整合发送的对象（供应商）的id
        handleCheckedChange(i, j, k) {
            if (this.checkedCities.length != 0) {
                this.sendIds.push(this.list[k])
                if (this.sendIds.length > 0) {
                    // this.sendIds.push(this.list[k])
                    this.sendIds = this.sendIds.filter((item, i, arr) => {
                        return arr.indexOf(item) === i
                    })
                }
                if (i == this.checkedCities[this.checkedCities.length - 1]) { this.totals += j }
                else {
                    this.totals -= j
                    this.sendIds.pop()
                }
            }
            else {
                this.totals = 0
            }
        },
        // 申请发票
        saveBill() {
            if (this.input.id) {
                this.$confirm('请确认信息是否正确?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    // 订单id数组、抬头id
                    rec_save({ order_id_array: this.checkedCities, receipt_header: this.input.id }).then(res => {
                        let i = res.data.id
                        if (res.status === 200) {
                            this.$message({
                                message: `申请发票${i}成功！`,
                                type: 'success'
                            });
                            // for (let index = 0; index < this.sendIds.length; index++) {
                            // let a = `项目方${this.user.consumer_name}向你申请结算单号为${this.sendIds[index].id}，发票号为${i}的发票，请注意查收！`
                            //   mes_add({receive_consumer_id: this.sendIds[index].seller_consumer_id, title: "项目方申请发票通知", type: 3, message_state: 2, content:a, send_consumer_id: this.user.id })
                            //   .then((res) => {
                            //     console.log(res);
                            //   }).catch((err) => {
                            //     console.log(err);
                            //   })
                            // }
                            this.checkedCities = []
                            this.sendIds = []
                        }
                    })
                    this.addBillDialogVisible = false
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            }
            else { this.$message({ message: '请选择抬头', type: 'warning' }); }
        },
        // 搜索
        searchList() {
            this.cityOptions = []
            this.sendIds = []
            this.checkedCities = []
            // let a = 0
            this.list = []
            if (this.searched == 1) {
                this.page.pageNumber = 1
                this.searched = 0
            }
            // // 全部页码
            // let num = (this.page.pageSize * this.page.pageNumber)
            // // 当前页码
            // let num1 = (this.page.pageSize * (this.page.pageNumber - 1))

            // if (this.search) {
            //     // for (let index = 0; index < this.listll.length; index++) {
            //     this.listB = this.listA.filter((listll) => {
            //         return Object.keys(listll).some(() => {
            //             return String(listll['id']).toLowerCase().indexOf(this.search) > -1;
            //         })
            //     })
            //     // }
            //     for (let i = num1; i < num; i++) {
            //         if (this.listB.length <= i) { break }
            //         else { this.list[a] = this.listB[i]; a++ }
            //     }
            //     this.total.totalRow = this.listB.length
            //     if (this.list) {
            //         for (let i = 0; i < this.list.length; i++) {
            //             this.cityOptions.push(this.list[i].id)
            //         }
            //         this.cities = this.cityOptions
            //     }
            // } else {
                this.searched = 1
                this.getAllList()
                this.listB = []
            // }

        },
        // 判断是否输入
        checkinput(i) {
            //var myreg = /^(((13[0-9]{1})|(14[0-9]{1})|(15[0-9]{1})|(17[0-9]{1})|(18[0-9]{1}))+\d{8})$/;
            switch (i) {
                case 1:
                    if (this.input.taxpayer_no == '') { this.$message({ message: '税号不能为空', type: 'warning' }); } else { this.checkinputs[i] = true }
                    break;
                case 2:
                    if (this.input.company_address == '') { this.$message({ message: '单位地址不能为空', type: 'warning' }); } else { this.checkinputs[i] = true }
                    break;
                case 3:
                    if (this.input.phone == '') { this.$message({ message: '电话号码不能为空', type: 'warning' }); }
                    // else if (!myreg.test(this.input.phone)) { this.$message({ message: '请输入正确的电话号码', type: 'warning' }); } else { this.checkinputs[i] = true }
                    break;
                case 4:
                    if (this.input.bank == '') { this.$message({ message: '开户银行不能为空', type: 'warning' }); } else { this.checkinputs[i] = true }
                    break;
                case 5:
                    if (this.input.bank_account == '') { this.$message({ message: '银行账号不能为空', type: 'warning' }); } else { this.checkinputs[i] = true }
                    break;
                case 6:
                    if (this.input.receiv_name == '') { this.$message({ message: '收件人姓名不能为空', type: 'warning' }); } else { this.checkinputs[i] = true }
                    break;
                case 7:
                    if (this.input.receiv_address == '') { this.$message({ message: '收件单位地址不能为空', type: 'warning' }); } else { this.checkinputs[i] = true }
                    break;
                case 8:
                    if (this.input.receiv_phone == '') { this.$message({ message: '收件电话不能为空', type: 'warning' }); }
                    // else if (!myreg.test(this.input.phone)) { this.$message({ message: '请输入正确的收件电话', type: 'warning' }); } else { this.checkinputs[i] = true }
                    break;
                case 9:
                    if (this.input.head_name == '') { this.$message({ message: '名称不能为空', type: 'warning' }); } else { this.checkinputs[i] = true }
                    break;
                default:
                    this.checkinputs[0] = true
                    break;
            }
            for (let index = 1; index < this.checkinputs.length; index++) {
                if (this.checkinputs[index]) {
                    this.checkinputs[0] = true
                } else {
                    this.checkinputs[0] = false; break;
                }
            }
        },
        // 选择抬头
        selectbill() {
            this.addBillDialogVisible = false;
            this.addBillDialogVisible1 = true;
            rec_list().then(res => {
                this.blist = res.data
            })
        },
        select(index) {
            this.$confirm('是否选择该抬头进行开票?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.input = this.blist[index]
                this.addBillDialogVisible = true;
                this.addBillDialogVisible1 = false;
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });

        },
        handleClose() {
            this.$confirm('是否确认取消本次申请发票?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            })
                .then(() => {
                    this.input = this.deppInput
                    this.addBillDialogVisible = false;
                })
                .catch(() => {
                    this.$message.info('已取消')
                })
        },
        gotoBill() {
            this.$router.push({ path: '/personalCenter/billManage' })
            sessionStorage.setItem('go', 1)
            sessionStorage.setItem('goto', 1)
        }
    }
}
</script>

<style scoped>
/* 弹出框内部 */
.bill-manage-item {
    border: 1px solid #d8dce8;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0px 6px 20px 0px rgba(202, 200, 200, 0.4);
    margin-bottom: 10px;
    padding: 6px 30px;
    display: flex;
}

.bill-manage-item .info {
    flex: 1;
    border-left: 1px dashed #e6e6e6;
    padding: 14px 0 6px 20px;
}

.bill-manage-item .info .info-item {
    display: flex;
    font-size: 14px;
    color: #383838;
    margin-bottom: 14px;
}

.bill-manage-item .info .info-item .label {
    min-width: 100px;
    color: #a8aebe;
}

/* 按钮 */
.bill-manage-item .btn-group {
    border-right: 1px dashed #e6e6e6;
    display: flex;
    align-items: flex-end;
    padding-bottom: 14px;
    padding-right: 30px;
}

.bill-manage-item .btn-group .btn {
    height: 30px;
    border-radius: 4px;
    border: 1px solid #e2e2e2;
    background-color: #f1f3f5;
    min-width: 66px;
    padding: 0 8px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.3s;
}



.status[data-v-416920bf] {
    width: 78px;
    text-align: right;
}

.my-collection .list-manage-table .thead .th:last-of-type,
.my-collection .list-manage-table .tbody .td:last-of-type {
    width: 150px;
}

.my-collection .list-manage-table .thead .th:nth-of-type(4),
.my-collection .list-manage-table .tbody .td:nth-of-type(4) {
    width: 200px;
    text-align: center;
}

.my-collection .list-manage-table .thead .th:nth-of-type(3),
.my-collection .list-manage-table .tbody .td:nth-of-type(3) {
    width: 100px;
}

.list-manage .list-manage-table .thead .th:nth-of-type(1),
.list-manage .list-manage-table .tbody .td:nth-of-type(1) {
    width: 145px;
    padding-left: 20px;
}

.td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.GoBill {
    color: white;
    font-size: 20px;
    margin-left: 10px;
    cursor: pointer;
    transition: .2s;
}

.GoBill:hover {
    color: #ff992b
}

/deep/ .el-dialog {
    height: 80%;
    overflow: hidden;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0px !important;
    border-radius: 10px;
    position: relative;
}

/deep/ .el-dialog__body {
    height: 90%;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1;
    overflow-y: auto;
}

.base-btn {
    width: 170px;
    height: 50px;
    border-radius: 100px;
    margin-top: 30px;
}

.base-btn {
    height: 50px;
    padding: 0 12px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #ff992b;
    transition: .3s;
    cursor: pointer;
    font-size: 20px;
    color: #fff;
    font-weight: 700;
}

.searchBar {
    height: 50px;
    line-height: 50px;
    width: 200px;
    border-radius: 100px;
}

.searchBtn {
    margin-left: 10px;
    background-color: #FF992B;
    color: white;
    border-radius: 100px;
    border: 1px solid #FF992B;
}

.list-item-header {
    display: inline-block;
    font-size: 14px;
}

.status {
    width: 50px;
    text-align: right;
}

.pagination {
    display: flex;
    justify-content: space-between
}

.total {
    margin-left: 50px;
    height: 42px;
    line-height: 42px;
    font-size: 18px;
}
</style>
